/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

html,
body {
  color: #000000;
}

ion-row.header ion-col {
  font-weight: bold;
  border-bottom: 1px solid var(--ion-color-primary);
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  white-space: normal !important;
}

h1 {
  color: var(--ion-color-primary);
}

ion-button {
  text-transform: none;
}

ion-modal {
  --height: 90%;
}

.form-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 5px #dddddd;

  .ng-valid:not(form):not(ion-radio-group) {
    border: 1px solid var(--ion-color-primary);
  }

  .ng-invalid.ng-touched:not(form) {
    border: 1px solid var(--ion-color-danger);
  }

  .item-interactive.ion-valid {
    --highlight-background: none !important;
  }

  ion-list {
    background: #ffffff;
  }

  ion-item:nth-child(1) {
    margin-top: -20px;
  }

  ion-item:hover {
    --background-hover: transparent;
  }

  ion-item {
    --background: #ffffff;
    --highlight-height: 0px;

    ion-label {
      margin-bottom: 10px !important;
    }

    ion-input,
    ion-textarea,
    ion-select {
      border: 1px solid #cccccc;
      border-radius: 10px;
      --padding-start: 10px !important;
    }
  }

  select {
    width: 100%;
    border-radius: 10px;
    height: 37px;
    padding-left: 5px;
    background-color: #ffffff;
    border-color: #cccccc;
  }

  .item-interactive.ion-invalid {
    --highlight-background: none;
  }

  div[formGroupName] {
    margin-top: 20px;
  }

  div[formGroupName].ng-invalid,
  div[formGroupName].ng-valid {
    border: 0;
  }
}

ion-searchbar {
  --background: #ffffff !important;
}

.info-box {
  text-align: left !important;
  font-size: 0.75rem;
  padding: 0.8rem;
  margin: 1.25rem 0 0.25rem 0;
  border-radius: 0.5rem;
  background-color: #ffebcd;
  line-height: 1rem;
  width: 100%;
}

hr {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0;
}

ion-label.required::before {
  color: red;
  content: "*";
}
